import axios from "axios";

const role = sessionStorage.getItem("role");
let userAccessUrl;
if (role == 2) {
    userAccessUrl = "/v2/clearing/admin/abx";
} else if (role == 5) {
    userAccessUrl = "/v2/clearing/operator";
} else {
    userAccessUrl = "/v2/clearing/admin";
}

const getListDeposit = (serialNumber, offset, perPage, owner, startDate, endDate, status, type) => {
    return axios.get(userAccessUrl + "/get/list/deposit", {
        params: {
            serial_number: serialNumber,
            type: type,
            limit: perPage,
            offset: offset,
            owner: owner,
            startDate: startDate,
            endDate: endDate,
            status: status,
        },
    });
};

const getListMetal = (activity, perPage, offset, startDate, endDate, type, owner, serialNumber, hallmark, dateType, status, contract_id) => {
    return axios.get(userAccessUrl + "/get/list/bullion", {
        params: {
            activity: activity,
            limit: perPage,
            type: type,
            startDate: startDate,
            endDate: endDate,
            offset: offset,
            owner: owner,
            serial_number: serialNumber,
            hallmark: hallmark,
            date_type: dateType,
            status: status,
            contract_id: contract_id,
        },
    });
};

const getDetailDeposit = (id) => {
    return axios.get(userAccessUrl + "/detail/deposit/bullion/" + id);
};

const getWithdrawalOn = (offset, perPage, type, owner, startDate, endDate, status, serialNumber) => {
    return axios.get(userAccessUrl + "/get/list/withdrawal", {
        params: {
            startDate: startDate,
            endDate: endDate,
            limit: perPage,
            offset: offset,
            type: type,
            owner: owner,
            serial_number: serialNumber,
            status: status,
        },
    });
};

const getWithdrawOffApi = (offset, perPage, denom, pieces, startDate, endDate, status, search) => {
    return axios.get(userAccessUrl + "/offexchange/get/list/withdrawal", {
        params: {
            startDate: startDate,
            endDate: endDate,
            status: status,
            limit: perPage,
            offset: offset,
            denom: denom,
            pieces: pieces,
            search: search,
        },
    });
};
const getWithdrawOffDetailApi = (id) => {
    return axios.get("/v2/clearing/admin/offexchange/get/detail/withdrawal/" + id);
};

const getMetalApi = (activity, perPage, offset, year, metal_id, type, owner, serialNumber, status, withdrawal_id) => {
    return axios.get("/v2/clearing/admin/get/list/metalWithdrawal", {
        params: {
            withdrawal_id: withdrawal_id,
            owner: owner,
            activity: activity,
            type: type,
            year: year,
            skip: offset,
            limit: perPage,
            serial_number: serialNumber,
            status: status,
            metal_id: metal_id,
            showActiveMetal: "",
        },
    });
};

export { getListDeposit, getListMetal, getDetailDeposit, getWithdrawalOn, getWithdrawOffApi, getWithdrawOffDetailApi, getMetalApi };
