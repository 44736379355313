const token = sessionStorage.getItem("token");
const role = sessionStorage.getItem("role");
const isAuth = (to, from, next) => {
    // role == 2 || role == 1 || role == 13 || role == 15
    if (token) {
        if (role == 1 || role == 2 || role == 6 || role == 4) {
            next();
        } else {
            next("/");
        }
    } else {
        next("/login");
    }
};

export default {
    path: "/cash-management",
    name: "Cash Management",
    component: () => import("../views/cms/Layout.vue"),
    beforeEnter: isAuth,
    meta: {
        breadcrumb: "Cash Management",
    },
    children: [
        {
            path: "cash-in-out",
            name: "Deposit and Withdrawal",
            component: () => import("../views/cms/CashWithdrawal.vue"),
            meta: {
                breadcrumb: "Deposit and Withdrawal",
            },
        },
        {
            path: "portofolio",
            name: "Portofolio",
            component: () => import("../views/cms/Portofolio.vue"),
            meta: {
                breadcrumb: "Portofolio",
            },
        },
        {
            path: "chart",
            name: "Chart Portofolio",
            component: () => import("../views/cms/chart/ChartCM.vue"),
            meta: {
                breadcrumb: "Chart Portofolio",
            },
        },
        {
            path: "participants",
            name: "Participants",
            component: () => import("../views/cms/Users.vue"),
            meta: {
                breadcrumb: "Participants",
            },
        },
    ],
};
