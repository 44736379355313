import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import vaultManagement from "./vaultManagement/vault.store";
import cashManagement from "./cashManagement/cash.store";
import bullionManagement from "./bullionManagement/bullion.store";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        login: "",
        minute: "",
        status_login: null,
        geoData: [],
        token: sessionStorage.getItem("token"),
    },

    getters: {
        getStatusLogin(state) {
            return state.status_login;
        },
        isAuthenticated(state) {
            return state.token;
        },
    },
    mutations: {
        Login(state, payload) {
            state.login = payload;
        },
        set_interval(state, payload) {
            state.minute = payload;
        },
        LoginStatus(state, payload) {
            state.status_login = payload;
        },
        setGeoData(state, payload) {
            state.geoData = payload;
        },
    },
    actions: {
        getLocation({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    // .get("https://asia-southeast1-jfx-kbi-dev.cloudfunctions.net/get-user-access-location")
                    .get("https://asia-southeast2-kbi-prod.cloudfunctions.net/get-user-access-location")
                    .then((res) => {
                        resolve(res.data);
                        commit("setGeoData", res.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        LOGIN({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.get("https://asia-southeast2-kbi-prod.cloudfunctions.net/get-user-access-location").then((resGeo) => {
                    let latLong = resGeo.data.cityLatLong.split(",");
                    axios
                        .post("/v2/auth", data, {
                            headers: {
                                Latitude: latLong[0],
                                Longitude: latLong[1],
                                City: resGeo.data.city,
                                Region: resGeo.data.region,
                                Country: resGeo.data.country,
                                "IP-Address": resGeo.data.userIP,
                            },
                        })
                        .then((res) => {
                            commit("LoginStatus", res.data.data.status_code);
                            const token = res.data.data.token;
                            const username = res.data.data.username;
                            const role = res.data.data.role;
                            const user_id = res.data.data.user_id;
                            if (res.status == 200) {
                                sessionStorage.setItem("role", role);
                                sessionStorage.setItem("token", token);
                                sessionStorage.setItem("username", username);
                                sessionStorage.setItem("user_id", user_id);
                                sessionStorage.setItem("status_token", true);
                                sessionStorage.setItem("refreshTokenTime", Date.now() + 90000);
                            }
                            commit("Login", res);
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            });
        },

        setMinute({ commit }) {
            commit("set_interval", false);
            sessionStorage.setItem("set_minute", false);
            sessionStorage.removeItem("newtoken");
            setInterval(() => {
                commit("set_interval", true);
                sessionStorage.setItem("set_minute", true);
            }, 10 * 1000);
        },
    },
    modules: {
        vaultModule: vaultManagement,
        cashModule: cashManagement,
        bullionModule: bullionManagement,
    },
});
