import axios from "axios";
const role = sessionStorage.getItem("role");

const getCastDepositWithdrawal = (limit, offset, startDate, endDate, bank_code, credit, status) => {
    let url;

    if (role == 1) {
        url =
            "/v2/clearing/admin/get/cash_flow?bank_code=" +
            bank_code +
            "&type=" +
            credit +
            "&status=" +
            status +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&limit=" +
            limit +
            "&offset=" +
            offset;
    } else if (role == 2) {
        url =
            "/v2/clearing/admin/abx/get/cash_flow?bank_code=" +
            bank_code +
            "&type=" +
            credit +
            "&status=" +
            status +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&limit=" +
            limit +
            "&offset=" +
            offset;
    } else if (role == 4) {
        url =
            "/v2/clearing/admin/bank/get/cash_flow?bank_code=" +
            bank_code +
            "&type=" +
            credit +
            "&status=" +
            status +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&limit=" +
            limit +
            "&offset=" +
            offset;
    }

    return axios.get(url);
};

const getPortofolio = (perPage, offset, startDate, endDate) => {
    let url;
    if (role == 2) {
        url = "/v2/clearing/admin/abx/get/cash_management?limit=" + perPage + "&startDate=" + startDate + "&endDate=" + endDate + "&offset=" + offset;
    } else if (role == 4) {
        url = "/v2/clearing/admin/bank/get/cash_management?limit=" + perPage + "&startDate=" + startDate + "&endDate=" + endDate + "&offset=" + offset;
    } else if (role == 1) {
        url = "/v2/clearing/admin/get/cash_management?limit=" + perPage + "&startDate=" + startDate + "&endDate=" + endDate + "&offset=" + offset;
    }

    return axios.get(url);
};

const getTradeAccountUrl = (offset, perPage, keyWord, broker, bank, order, sortBy) => {
    return axios.get("/v2/clearing/admin/get/trade_account", {
        params: {
            offset: offset,
            limit: perPage,
            keyword: keyWord,
            broker: broker,
            bank: bank,
            sortby: sortBy,
            order: order,
        },
    });
};

const getBalanceUrl = (accountNo) => {
    return axios.get("/v2/clearing/admin/get/account_balance", {
        params: {
            account_no: accountNo,
        },
    });
};

const getTotalGram = (paramsData) => {
    return axios.get("/v2/clearing/admin/get/total_gold", {
        params: {
            account_id: paramsData,
        },
    });
};

const getSumaryUrl = (perpage, offset, typeInventory) => {
    return axios.get("v2/clearing/admin/get/list/summary_inventory", {
        params: {
            limit: perpage,
            offset: offset,
            type: typeInventory,
            startDate: "",
            endDate: "",
        },
    });
};

export { getCastDepositWithdrawal, getPortofolio, getTradeAccountUrl, getBalanceUrl, getTotalGram, getSumaryUrl };
