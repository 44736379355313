import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import VueMoment from "vue-moment";
import moment from "moment";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import JsonExcel from "vue-json-excel";
import Vuelidate from "vuelidate";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false;
Vue.use(Toast, {
    position: "top-center",
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);

// Vue.use(VueMoment);

moment.locale("en-us");
Vue.filter("formatDateTime", function (value) {
    if (value) {
        moment.locale("en-us");
        return moment(String(value)).subtract(7, "hours").format("DD MMMM YYYY HH:mm:ss");
    }
});

Vue.filter("formatDateTimeReal", function (value) {
    if (value) {
        moment.locale("en-us");
        return moment(String(value)).format("DD MMMM YYYY HH:mm:ss");
    }
});

Vue.filter("formatIso", function (value) {
    if (value) {
        moment.locale("en-us");
        return moment(String(value)).format("YYYY-MM-DD");
    }
});

Vue.filter("formatDate", function (value) {
    if (value) {
        moment.locale("en-us");
        return moment(String(value)).format("DD MMMM YYYY");
    }
});

Vue.filter("liveTime", function (value) {
    if (value) {
        return moment(String(value)).format("LLLL");
    }
});
// axios.defaults.baseURL = "https://api2-dev.ptkbi.com";
axios.defaults.baseURL = "https://api2-v2.ptkbi.com";

let token = sessionStorage.getItem("token");
if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
