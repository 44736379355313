<template>
    <div class="wrap-dashboard">
        <div class="content">
            <div class="head-content">
                <div class="title-content">
                    <div class="content-text">
                        <span>Name</span>
                        <span> : </span>
                        <span>{{ dataGold.fullname }}</span>
                    </div>
                    <div class="content-text">
                        <span>HIN</span>
                        <span> : </span>
                        <span>{{ dataGold.hin }}</span>
                    </div>
                    <div class="content-text">
                        <span>Status</span>
                        <span> : </span>
                        <span>
                            <span v-if="remaining_stock > 10000"> Available</span>
                            <span v-else-if="remaining_stock < 10000 && remaining_stock > 5000"> Consider</span>
                            <span v-else-if="remaining_stock < 5000"> Suspended</span>
                        </span>
                    </div>
                </div>
                <!-- <div class="wrap-download text-right">
                    <button class="btn btn-scondary mb-3" @click="openMenu">Download</button>
                    <div class="menu-download" v-if="toggleDownload">
                        <form action="">
                            <input
                                placeholder="Input start date"
                                type="text"
                                onfocus="(this.type='date')"
                                onblur="(this.type='text')"
                                id="start-date"
                                v-model="form.startDate"
                                :max="today"
                            />
                            <input
                                placeholder="Input end date"
                                type="text"
                                onfocus="(this.type='date')"
                                onblur="(this.type='text')"
                                id="start-date"
                                v-model="form.endDate"
                                :max="today"
                            />
                            <div v-b-modal.modal-1 class="btn btn-primary btn-sm" type="button">Apply</div>
                        </form>
                    </div>
                    <b-modal size="xl" id="modal-1" title="Download Deposit Confirmation" hide-footer>
                        <div class="btn btn-success" type="button" @click="downloadDeposit">Dowanload</div>
                        <export-deposit :dataDownload="form" ref="downloadmenu" id="downloadMenu"></export-deposit>
                    </b-modal>
                </div> -->
            </div>
            <div class="wrap-summary">
                <div class="summary-data">
                    <div>Total Buy</div>
                    <div>
                        <span>{{ formatPrice(dataGold.gram_buy) }}</span>
                        <span>gr</span>
                    </div>
                </div>
                <div class="summary-data">
                    <div>Total Sell</div>
                    <div>
                        <span>{{ formatPrice(dataGold.gram_sell) }}</span>
                        <span>gr</span>
                    </div>
                </div>
                <div class="summary-data">
                    <div>Netting</div>
                    <div>
                        <span>{{ formatPrice(dataGold.netting) }}</span>
                        <span>gr</span>
                    </div>
                </div>
                <div class="summary-data">
                    <div>Remaining Stock</div>
                    <div>
                        <span>{{ formatPrice(remaining_stock) }}</span>
                        <span>gr</span>
                    </div>
                </div>
                <div class="summary-data">
                    <div>Total Stock</div>
                    <div>
                        <span>{{ formatPrice(dataGold.total_stock) }}</span>
                        <span>gr</span>
                    </div>
                </div>
            </div>
            <table>
                <thead>
                    <th>No</th>
                    <th>Contract</th>
                    <th>Total Pieces</th>
                    <th>Total Stock</th>
                    <th>Buy</th>
                    <th>Sell</th>
                    <th>Netting</th>
                    <th>Remaining Stock</th>
                </thead>
                <tbody>
                    <tr v-for="(detail, i) in dataDetail" :key="i">
                        <td>{{ (currentPage - 1) * perPage + (i + 1) }}</td>
                        <td>{{ detail.contract_name }}</td>
                        <td>{{ formatPrice(detail.pieces) }}</td>
                        <td>{{ formatPrice(detail.weight) }}</td>
                        <td>{{ formatPrice(detail.gram_buy) }}</td>
                        <td>{{ formatPrice(detail.gram_sell) }}</td>
                        <td>{{ formatPrice(detail.netting) }}</td>
                        <td>{{ formatPrice(detail.remaining) }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-end mt-3">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="lengthData"
                    :per-page="perPage"
                    @change="handlePageChange"
                    aria-controls="new-table"
                    first-number
                    last-number
                    v-show="lengthData > 10"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import ExportDeposit from "../../../components/ExportDeposit.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
    // components: { ExportDeposit },
    data() {
        return {
            dataDetail: [],
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            withdrawal_id: "",
            offset: 0,
            role: "",
            toggleDownload: false,
            type: "text",
            form: {
                startDate: "",
                endDate: "",
                depositor: "",
            },
            today: "",
            dataGold: null,
            remaining_stock: "",
        };
    },
    mounted() {
        // this.getData(this.currentPage);
        this.withdrawal_id = this.$route.params.id;
        this.form.depositor = this.$route.params.id;
        this.today = new Date().toISOString().split("T")[0];
        this.role = sessionStorage.getItem("role");
        this.getData(this.currentPage);
        axios.get("v2/clearing/admin/get/total_gold?hin=" + this.withdrawal_id).then((res) => {
            this.dataGold = res.data.data.body;
            this.remaining_stock = this.dataGold.total_stock - this.dataGold.netting;
        });
    },
    methods: {
        formatPrice(value) {
            let val = Math.round((value + Number.EPSILON) * 100) / 100;
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        openMenu() {
            this.toggleDownload = !this.toggleDownload;
        },
        handlePageChange(value) {
            this.getData(value);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        getData(value) {
            let offset = (value - 1) * this.perPage;
            let url;
            if (this.role == 2 || this.role == 3) {
                url = "v2/clearing/admin/abx/get/detail/summary_inventory?startDate&endDate&limit&offset=" + offset + "&type=&depositor=" + this.withdrawal_id;
            } else {
                url = "v2/clearing/admin/get/detail_total_gold?&hin=" + this.withdrawal_id;
            }

            axios.get(url).then((res) => {
                this.dataDetail = res.data.data.body;

                this.lengthData = res.data.data.count;
            });
        },
        downloadDeposit() {
            // let fileName = "deposit-confirmation.pdf";
            let dataId = document.getElementById("downloadMenu");
            let pdf = new jsPDF({
                orientation: "portrait",
                format: "a4",
            });
            html2canvas(dataId, { useCORS: true, scale: 1 }).then((canvas) => {
                var margin = 8;
                var imgWidth = 210 - 2 * margin;
                var imgHeight = (canvas.height * imgWidth) / canvas.width;
                var pageHeight = 295;
                var heightLeft = imgHeight;
                let img = canvas.toDataURL("image/png", 1);

                var position = 1;
                pdf.addImage(img, "PNG", margin, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(img, "PNG", margin, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                pdf.save("deposit-confirmation");
            });
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.content-text {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.content-text span:first-child {
    width: 2.5rem;
    font-weight: bold;
}

.wrap-left,
.wrap-right {
    width: 49%;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 11%);
    padding: 1.5rem;
    border-radius: 5px;
}

.fa-check {
    color: #28a745;
    cursor: pointer;
}
.fa-times {
    color: #dc3545;
    cursor: pointer;
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.form-group {
    margin-bottom: 1.7rem;
}

label {
    display: block;
    width: 100%;
    font-weight: bold;
}

input {
    font-size: 13px;
}

.buuton-add {
    text-align: right;
    font-size: 13px;
}

/* .wrap-content {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 25px;
} */

.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.filter {
    display: table-cell;
    width: 20%;
    font-size: 12px;
}
.wrap-input {
    display: flex;
}

select {
    width: 130px;
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.wrap-summary,
.head-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
    gap: 1rem;
}

.summary-data {
    /* box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    width: 22%;
    border: solid 1px #dddddd;
}

.summary-data div:last-child {
    float: right;
}

.summary-data div:last-child span:first-child {
    font-size: 1.75rem;
    margin-right: 0.5rem;
    font-weight: bold;
}

.summary-data:hover {
    /* box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.status-allocated {
    font-weight: 600;
    color: #2dce89;
}

.status-processed {
    font-weight: 600;
    color: rgb(172, 143, 26);
}

.status-rejected {
    font-weight: 600;
    color: #f5365c;
}

.wrap-download {
    position: relative;
}

.menu-download {
    position: absolute;
    margin-bottom: 1rem;
    background: #fff;
    width: 17rem;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 1rem;
    font-size: 13px;
    right: 0;
    top: 42px;
}

select,
input {
    border-radius: 3px;
    border: 1px solid #a5a5a5a5;
    padding: 3px;
    width: 100%;
    margin-bottom: 0.5rem;
}

input:last-child {
    margin-bottom: unset;
}
</style>
