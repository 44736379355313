<template>
  <transition name="fade">
    <div class="wrap-modal">
      <div class="modal-backdrop" @click="closeModal()" />
      <div class="modal-content">
        <div class="header-modal">
          <slot name="header"> </slot>
          <!-- <b-icon icon="x-lg" class="close" @click="closeModal()"></b-icon> -->
        </div>
        <div class="body-modal">
          <slot name="body"> this is body </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"> this is footer </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
  },
};
</script>

<style scoped>
.wrap-modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.modal-content {
  background-color: #ffffff;
  position: relative;
  width: 600px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  z-index: 2;
}

.close {
  cursor: pointer;
}

.header-modal {
  padding: 20px 20px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
}

.body-modal {
  padding: 10px 20px 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.modal-footer {
  padding: 10px 20px 20px;
}

@media screen and (max-width: 992px) {
  .modal {
    width: 90%;
    top: 50%;
    transform: translateY(-100%);
  }
}
</style>
