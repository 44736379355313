import Vue from "vue";
import VueRouter from "vue-router";
import OffExchangeRoute from "./offExchange.router";
import OnExchangeRoute from "./onExchange.router";
import cashManagementRouter from "./cashManagement.router";
// import store from "../store/index";

Vue.use(VueRouter);

const token = sessionStorage.getItem("token");
const role = sessionStorage.getItem("role");
const isAuth = (to, from, next) => {
    // role == 2 || role == 1 || role == 13 || role == 15
    if (token) {
        if (role == 1 || role == 2 || role == 6 || role == 4) {
            next();
        } else {
            next("/");
        }
    } else {
        next("/login");
    }
};

const authGudang = (to, from, next) => {
    if (token) {
        if (role == 5) {
            next();
        } else {
            next("/operator-deposite");
        }
    } else {
        next("/login");
    }
};

const authBappebti = (to, from, next) => {
    if (token) {
        if (role == 8) {
            next();
        } else {
            next("/bappebti-inventory");
        }
    } else {
        next("login");
    }
};

const isAuthDefault = (to, from, next) => {
    if (token) {
        next();
    } else {
        next("/login");
    }
};

// const authOperator = (to, from, next) => {
//   if(token){

//   }
// }

const routes = [
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login.vue"),
    },
    {
        path: "/test",
        name: "Test",
        component: () => import("../components/ExportExcel.vue"),
    },
    {
        path: "/admin-pos",
        component: () => import("../layout/AdminPosLayout.vue"),
        children: [
            {
                path: "/",
                name: "dashboarAdminPos",
                component: () => import("../views/Dashboard.vue"),
                beforeEnter: isAuth,
            },
        ],
    },
    {
        path: "",
        component: () => import("../layout/AdminLayout.vue"),
        children: [
            {
                ...OffExchangeRoute,
            },
            {
                ...OnExchangeRoute,
            },
            {
                ...cashManagementRouter,
            },
            {
                path: "/",
                name: "Dashboard",
                component: () => import("../views/Dashboard.vue"),
                beforeEnter: isAuth,
                meta: {
                    breadcrumb: "Clearing and Settlement",
                },
            },
            {
                path: "/operator-deposite",
                name: "DepositeOperator",
                component: () => import("../views/operator/Deposite.vue"),
                beforeEnter: authGudang,
            },
            {
                path: "/operator-withdrawal",
                name: "withdrawalOperator",
                component: () => import("../views/operator/Withdrawal.vue"),
                beforeEnter: authGudang,
            },
            {
                path: "/holders",
                name: "Holders",
                component: () => import("../views/VmHolders.vue"),
                beforeEnter: isAuth,
            },
            {
                path: "/balance-management",
                name: "BalanceManagement",
                component: () => import("../views/BalanceManagement.vue"),
                beforeEnter: isAuth,
            },
            {
                path: "/deposite-off",
                name: "DepositeOff",
                component: () => import("../components/deposit/DepositOffExchngeTemp.vue"),
                beforeEnter: isAuth,
            },
            {
                path: "/withdrawal",
                name: "Withdraw",
                component: () => import("../views/Withdraw.vue"),
                beforeEnter: isAuth,
            },
            {
                path: "/withdrawalOff",
                name: "WithdrawOff",
                component: () => import("../components/withdrawal/WithdrawOffTemp.vue"),
                beforeEnter: isAuth,
            },
            // {
            //     path: "/portfolio",
            //     name: "portfolio",
            //     component: () => import("../views/Portofolio.vue"),
            //     beforeEnter: isAuth,
            // },
            // {
            //     path: "/chart",
            //     name: "chart",
            //     component: () => import("../views/chart/ChartCM.vue"),
            //     beforeEnter: isAuth,
            // },
            {
                path: "/detail-withdrawal/:id",
                name: "DetailWithdraw",
                component: () => import("../views/Detail.vue"),
                beforeEnter: isAuth,
            },
            {
                path: "/detail-withdrawal-off/:id",
                name: "DetailWithdrawOff",
                component: () => import("../components/withdrawal/WithdrawOffDetail.vue"),
                beforeEnter: isAuth,
            },
            {
                path: "/operator-detail-withdrawal/:id",
                name: "OperatorDetailWithdraw",
                component: () => import("../views/Detail.vue"),
                beforeEnter: authGudang,
            },
            {
                path: "/data-excel",
                name: "DataExcel",
                component: () => import("../components/ExportExcel"),
                beforeEnter: isAuth,
            },
            {
                path: "/detail-deposit/:id",
                name: "DetailDeposite",
                component: () => import("../views/DetailDeposite.vue"),
                beforeEnter: isAuthDefault,
            },
            {
                path: "/detail-history/:id",
                name: "DetailVMHistory",
                component: () => import("../views/DetailVMHistory.vue"),
                beforeEnter: isAuth,
            },
            {
                path: "/operator-detail-deposit/:id",
                name: "OperatorDetailDeposite",
                component: () => import("../views/DetailDeposite.vue"),
                beforeEnter: authGudang,
            },
            {
                path: "/profil",
                name: "Profil",
                component: () => import("../views/Profil.vue"),
                beforeEnter: isAuthDefault,
            },
            {
                path: "/bappebti-inventory",
                name: "Inventory Bappebti",
                component: () => import("../views/vms/onExchange/InventroyBappebti.vue"),
                beforeEnter: authBappebti,
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
