// import axios from "axios";
import * as service from "./cash.service";
const cashManagement = {
    state: {
        dataShow: [],
        lengthData: 1,
        tradeAccountData: [],
        totalTradeAccountData: null,
        isLoadingData: false,
    },
    mutations: {
        setCashDepoWith(state, payload) {
            state.dataShow = payload.dataRes;
            state.lengthData = payload.count;
        },
        setCashMene(state, payload) {
            state.dataShow = payload.dataRes;
            state.lengthData = payload.count;
        },
        setTradeAccountData(state, payload) {
            state.tradeAccountData = payload;
        },
        setTradeTotalData(state, payload) {
            state.totalTradeAccountData = payload;
        },
        setIsloadingData(state, payload) {
            state.isLoadingData = payload;
        },
    },
    getters: {
        getDataTrade(state) {
            return state.tradeAccountData;
        },
    },
    actions: {
        loadCashDepWith({ commit }, { perPage, currentPage, startDate, endDate, bank_code, credit, status }) {
            return new Promise((resolve, reject) => {
                let offset = (currentPage - 1) * perPage;
                service
                    .getCastDepositWithdrawal(perPage, offset, startDate, endDate, bank_code, credit, status)
                    .then((res) => {
                        let dataRes = res.data.data.body;
                        let count = res.data.data.count;
                        for (let i = 0; i < dataRes.length; i++) {
                            let newDescription;
                            newDescription = dataRes[i].desc;
                            if (newDescription.includes("-")) {
                                dataRes[i].newDescription = newDescription.split("-");
                            } else {
                                dataRes[i].newDescription = newDescription.split(":");
                            }
                        }
                        commit("setCashDepoWith", { dataRes, count });
                        resolve(dataRes);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        loadCashManagement({ commit }, { perPage, currentPage, startDate, endDate }) {
            return new Promise((resolve, reject) => {
                let offset = (currentPage - 1) * perPage;
                service
                    .getPortofolio(perPage, offset, startDate, endDate)
                    .then((res) => {
                        let dataRes = res.data.data.body;
                        let count = res.data.data.count;
                        commit("setCashMene", { dataRes, count });
                        resolve(dataRes);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getTradeAccount({ commit }, paramsUrl) {
            return new Promise((resolve, reject) => {
                commit("setIsloadingData", true);
                let bankCode = [];
                let brokerCode = [];
                if (paramsUrl.bank) {
                    bankCode = paramsUrl.bank.map((data) => data.bank_code);
                }
                if (paramsUrl.broker) {
                    brokerCode = paramsUrl.broker.map((data) => data.broker_code);
                }
                let offset = (paramsUrl.currentPage - 1) * paramsUrl.perPage;
                // let tradeDatas = [];
                // if (paramsUrl.bank.length == 0) {
                //     paramsUrl.bank = "";
                // }
                // if (paramsUrl.broker.length == 0) {
                //     paramsUrl.broker = "";
                // }
                service
                    .getTradeAccountUrl(offset, paramsUrl.perPage, paramsUrl.keyWord, brokerCode, bankCode, paramsUrl.order, paramsUrl.sortBy)
                    .then((res) => {
                        commit("setTradeTotalData", res.data.data.count);
                        let dataTrade = res.data.data.body;
                        commit("setTradeAccountData", dataTrade);
                        commit("setIsloadingData", false);
                        // for (let i = 0; i < dataTrade.length; i++) {
                        //     let newObject = {};
                        //     axios
                        //         .all([service.getBalanceUrl(dataTrade[i].bank_account[0].account_no), service.getTotalGram(dataTrade[i].trade_account.account_id)])
                        //         .then(
                        //             axios.spread((...response) => {
                        //                 console.log(response);
                        //             })
                        //         );
                        //     service
                        //         .getBalanceUrl(dataTrade[i].bank_account[0].account_no)
                        //         .then((resBalance) => {
                        //             newObject = {
                        //                 fullname: dataTrade[i].fullname,
                        //                 hin: dataTrade[i].trade_account.hin,
                        //                 bank_name: dataTrade[i].bank_account[0].name,
                        //                 rped: dataTrade[i].bank_account[0].account_no,
                        //                 balance_no: resBalance.data.data.body.Balance,
                        //                 gold_balance: "",
                        //                 broker_name: "",
                        //             };

                        //             tradeDatas.push(newObject);
                        //             if (tradeDatas.length == paramsUrl.perPage) {
                        //                 commit("setTradeAccountData", tradeDatas);
                        //                 commit("setIsloadingData", false);
                        //             }
                        //         })
                        //         .catch((errBal) => {
                        //             reject(errBal);
                        //             commit("setIsloadingData", false);
                        //         });
                        // }
                        resolve(dataTrade);
                    })
                    .catch((resErr) => {
                        reject(resErr);
                        commit("setIsloadingData", false);
                    });
            });
        },
    },
};

export default cashManagement;
