const token = sessionStorage.getItem("token");
const role = sessionStorage.getItem("role");
const isAuth = (to, from, next) => {
    // role == 2 || role == 1 || role == 13 || role == 15
    if (token) {
        if (role == 1 || role == 2 || role == 6 || role == 4) {
            next();
        } else {
            next("/");
        }
    } else {
        next("/login");
    }
};

export default {
    path: "/off-exchange",
    name: "OffExchange",
    component: () => import("../views/vms/offExcahnge/Layout.vue"),
    beforeEnter: isAuth,
    meta: {
        breadcrumb: "Off Exchange",
    },
    children: [
        {
            path: "deposit",
            component: () => import("../views/vms/offExcahnge/deposit/LayoutDeposit.vue"),
            meta: {
                breadcrumb: "Deposit",
            },
            children: [
                {
                    path: "",
                    name: "Deposit Off",
                    component: () => import("../views/vms/offExcahnge/deposit/Deposit.vue"),
                    meta: {
                        breadcrumb: "Deposit",
                    },
                },
                {
                    path: ":id",
                    name: "Detail Deposit Off",
                    component: () => import("../views/vms/offExcahnge/deposit/DetailDeposit.vue"),
                    meta: {
                        breadcrumb: "Detail Deposit",
                    },
                },
            ],
        },
        {
            path: "withdrawal",
            component: () => import("../views/vms/offExcahnge/withdrawal/LayoutWithdrawal.vue"),
            meta: {
                breadcrumb: "Withdrawal",
            },
            children: [
                {
                    path: "",
                    name: "Withdrawal Off",
                    component: () => import("../views/vms/offExcahnge/withdrawal/Withdrawal.vue"),
                    meta: {
                        breadcrumb: "Withdrawal",
                    },
                },
                {
                    path: ":id",
                    name: "Detail Withdrawal Off",
                    component: () => import("../views/vms/offExcahnge/withdrawal/DetailWithdrawal.vue"),
                    meta: {
                        breadcrumb: "Detail Withdrawal",
                    },
                },
            ],
        },
        {
            path: "inventory",
            component: () => import("../views/vms/offExcahnge/inventory/LayoutInventory.vue"),
            meta: {
                breadcrumb: "Inventory",
            },
            children: [
                {
                    path: "",
                    name: "Inventory Off",
                    component: () => import("../views/vms/offExcahnge/inventory/VmInventory.vue"),
                    meta: {
                        breadcrumb: "Inventory",
                    },
                },
                {
                    path: ":id",
                    name: " Detail Inventory off",
                    component: () => import("../views/vms/offExcahnge/inventory/DetailInventory.vue"),
                    meta: {
                        breadcrumb: "Detail Inventory",
                    },
                },
            ],
        },
    ],
};
