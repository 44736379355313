<template>
    <div id="app">
        <router-view />
        <Modal v-show="statusLogin">
            <template v-slot:header> </template>
            <template v-slot:body> Maaf Anda tidak aktif selama 15 menit silahkan login kembali </template>
            <template v-slot:footer>
                <button class="btn btn-primer" @click="logout">Login</button>
            </template>
        </Modal>
    </div>
</template>

<script>
import axios from "axios";
import Modal from "./components/Modal.vue";
export default {
    components: {
        Modal,
    },
    data() {
        return {
            set_minute: sessionStorage.getItem("set_minute"),
            events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
            statusLogin: false,
        };
    },
    created() {
        let token = sessionStorage.getItem("token");
        // if (token) {
        //   this.events.forEach((event) => {
        //     window.addEventListener(event, this.resetTimer);
        //   }, this);

        //   this.startTimer();
        // }
        if (token) {
            const cekStatus = setInterval(() => {
                if (!token) {
                    clearInterval(cekStatus);
                }
                sessionStorage.setItem("status_token", false);
                let newStatus = sessionStorage.getItem("status_token");
                this.tokenRefresh(newStatus);
            }, 2.5 * 60 * 1000);
        }
    },
    methods: {
        logout() {
            sessionStorage.clear();
            delete axios.defaults.headers.common["Authorization"];
            this.$router.go("/login");
        },
        handleInactive() {
            this.statusLogin = true;
            clearTimeout(this.timeoutId);
        },

        startTimer() {
            this.timeoutId = setTimeout(this.handleInactive, 14 * 60 * 1000);
        },

        resetTimer() {
            clearTimeout(this.timeoutId);
            this.startTimer();
        },
        setupTimers() {
            this.startTimer();
        },
        refreshToken() {
            const intervalRefreshToken = setInterval(() => {
                const token = sessionStorage.getItem("token");
                if (!token) {
                    clearInterval(intervalRefreshToken);
                }

                let refreshTokenTime = Number(localStorage.getItem("refreshTokenTime"));

                if (refreshTokenTime !== undefined && refreshTokenTime != 0 && Date.now() > refreshTokenTime && this.status != "loading") {
                    this.status = "loading";
                    axios({
                        method: "get",
                        url: "/v1/clearing/refresh",
                    })
                        .then((response) => {
                            this.status = "success";
                            const newToken = response.data.data.token;
                            axios.defaults.headers.common["Authorization"] = "Bearer " + newToken;
                            sessionStorage.setItem("token", newToken);
                            // this.$store.commit('SET_TOKEN', newToken)

                            localStorage.setItem("refreshTokenTime", Date.now() + 90000); //3 minutes
                        })
                        .catch((e) => {
                            console.log(e.response);
                        });

                    // clearInterval(intervalRefreshToken)
                }
            }, 1000);
        },
        tokenRefresh(tokenStatus) {
            let id = sessionStorage.getItem("user_id");
            if (tokenStatus == "false") {
                axios
                    .post("/v2/auth/refresh_token", {
                        user_id: id,
                    })
                    .then((res) => {
                        const newToken = res.data.data.token;
                        axios.defaults.headers.common["Authorization"] = "Bearer " + newToken;
                        sessionStorage.setItem("token", newToken);
                    });
                sessionStorage.setItem("status_token", true);
            }
        },
    },
};
</script>

<style>
#app {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    font-size: 14px;
}
:root {
    --primary-color: #005f7f;
    --scondary-color: #a28548;
    --scondary-color-hover: #71581d;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul {
    padding: 0;
}

ul li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #2c3e50;
}

a:hover {
    text-decoration: none;
}

.wrap-content {
    width: 100%;
    padding: 2.5rem;
}

.item-dropdown {
    cursor: pointer;
    font-size: 14px;
    text-align: left;
}

.error {
    color: #e74c3c;
    font-size: 13px;
}

.item-dropdown:hover {
    background: #f2f2f2;
}

.dropdown-header {
    position: absolute;
    right: 0;
    border: solid 1px rgba(112, 112, 112, 0.4);
    -webkit-box-shadow: 0 0px 5px 0 rgba(10, 10, 10, 0.76);
    box-shadow: 0 0px 2px 0 rgba(1, 2, 5, 0.55);
    width: 170px;
    border-radius: 5px;
    background: #fff;
}

.btn-primary {
    background-color: #243a72 !important;
    border: none !important;
}

.self-modal {
    z-index: 999;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    right: -17px;
    width: 102%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.content-modal {
    background: white;
    position: relative;
    width: 650px;
    margin: 5% auto;
    padding: 20px;
    border-radius: 10px;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

.title-modal {
    height: 50px;
    /* border: inherit; */
    font-size: 20px;
    font-weight: bold;
}

.footer-modal {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.footer-modal div {
    text-align: center;
    line-height: 2;
}

.modal-header .modal-body .modal-footer {
    padding: 1rem 1.7rem !important;
}

.button {
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.button:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn {
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    padding: 0.625rem 1.25rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 600;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-weight: normal;
}

.btn-outline-warning {
    color: #fb6340;
    background-color: transparent;
    background-image: none;
    border-color: #fb6340;
}

.btn-outline-warning:hover {
    color: #fff;
    background-color: #fb6340;
    border-color: #fb6340;
}

.btn:hover {
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
}

.btn-primer {
    background: var(--primary-color);
    color: #fff;
}

.btn-primer:hover {
    color: #fff;
}

table {
    border: 1px solid #ededed;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: auto;
}

table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
}

table tr {
    background-color: #fff;
    border: 1px solid #ededed;
    padding: 0.35em;
}

table tbody tr:nth-child(even) {
    background-color: #f8f8f8;
}

table th,
table td {
    padding: 0.625em;
    text-align: center;
    /* word-wrap: break-word; */
}
table td {
    font-size: 14px;
}

table th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border: 1px solid #ededed;
}

#top th :nth-child(0) {
    width: 70px;
}

@media screen and (max-width: 600px) {
    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
    }

    table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right;
    }

    table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    table td:last-child {
        border-bottom: 0;
    }

    .wrap-content {
        padding: 1.5rem 1rem;
    }
}
</style>
