// import * as service from "./vault.service";
const vaultManagement = {
  state: {
    dataShow: [],
    lengthData: 1,
  },
  mutations: {
    setCashDepoWith(state, payload) {
      state.dataShow = payload.dataRes;
      state.lengthData = payload.count;
    },
    setCashMene(state, payload) {
      state.dataShow = payload.dataRes;
      state.lengthData = payload.count;
    },
  },
  actions: {
    // loadCashDepWith({ commit }, { perPage, currentPage, startDate, endDate, bank_code, type, credit, status }) {
    //   return new Promise((resolve, reject) => {
    //     let offset = (currentPage - 1) * perPage;
    //     service
    //       .getCastDepositWithdrawal(perPage, offset, startDate, endDate, bank_code, type, credit, status)
    //       .then((res) => {
    //         let dataRes = res.data.data.body;
    //         let count = res.data.data.count;
    //         for (let i = 0; i < dataRes.length; i++) {
    //           let newDescription;
    //           newDescription = dataRes[i].desc;
    //           dataRes[i].newDescription = newDescription.split("-");
    //         }
    //         commit("setCashDepoWith", { dataRes, count });
    //         resolve();
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
  },
};

export default vaultManagement;
