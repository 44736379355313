import axios from "axios";
import * as service from "./bullion.service";

const bullionManagement = {
    state: {
        dataShow: [],
        lengthData: 1,
        dataWithdrawal: {},
        dataMetal: [],
        lengthDataWithdrawal: 0,
        isloading: false,
        clientData: [],
        contractData: [],
        isLoadingWithdraw: false,
    },
    mutations: {
        setDataBullion(state, payload) {
            state.dataShow = payload.dataRes;
            state.lengthData = payload.count;
        },
        setDataWithdrawalDetail(state, payload) {
            state.dataWithdrawal = payload.body;
        },
        setIsLoading(state, payload) {
            state.isLoadingWithdraw = payload;
            state.isloading = payload;
        },
        setDataCLient(state, payload) {
            state.clientData = payload;
        },
        setDataContract(state, payload) {
            state.contractData = payload;
        },
        setDataMetal(state, payload) {
            state.dataMetal = payload.dataRes;
            state.lengthData = payload.count;
        },
    },
    getters: {
        getFilterBySerialNumber: (state) => (serialNumber) => {
            return state.dataWithdrawal.metal_info.filter((newData) => {
                // newData = newData.toLowerCase();
                return newData.serial_number.indexOf(serialNumber) > -1;
            });
        },
    },
    actions: {
        getDepositList({ commit }, { serialNumber, perPage, currentPage, owner, startDate, endDate, status, type }) {
            return new Promise((resolve, reject) => {
                let offset = (currentPage - 1) * perPage;
                commit("setIsLoading", true);
                service
                    .getListDeposit(serialNumber, offset, perPage, owner, startDate, endDate, status, type)
                    .then((res) => {
                        let dataRes = res.data.data.body;
                        let count = res.data.data.count;
                        commit("setDataBullion", { dataRes, count });
                        resolve(res.data.data);
                        commit("setIsLoading", false);
                    })
                    .catch((error) => {
                        commit("setIsLoading", false);
                        reject(error.response.data.message);
                    });
            });
        },
        getListMetal({ commit }, { activity, perPage, currentPage, startDate, endDate, type, owner, serialNumber, hallmark, dateType, status, contract_id }) {
            return new Promise((resolve, reject) => {
                let offset = (currentPage - 1) * perPage;
                commit("setIsLoading", true);
                service
                    .getListMetal(activity, perPage, offset, startDate, endDate, type, owner, serialNumber, hallmark, dateType, status, contract_id)
                    .then((res) => {
                        let dataRes = res.data.data.body;
                        let count = res.data.data.count;
                        commit("setDataBullion", { dataRes, count });
                        commit("setIsLoading", false);
                        resolve(res.data.data);
                    })
                    .catch((error) => {
                        commit("setIsLoading", false);
                        reject(error);
                    });
            });
        },
        getMetalList({ commit }, { activity, perPage, currentPage, year, metal_id, type, owner, serialNumber, status, withdrawal_id }) {
            return new Promise((resolve, reject) => {
                let offset = (currentPage - 1) * perPage;
                commit("setIsLoading", true);
                service
                    .getMetalApi(activity, perPage, offset, year, metal_id, type, owner, serialNumber, status, withdrawal_id)
                    .then((res) => {
                        let dataRes = res.data.data;
                        let count = res.data.total;
                        commit("setDataMetal", { dataRes, count });
                        commit("setIsLoading", false);
                        resolve(res.data.data);
                    })
                    .catch((error) => {
                        commit("setIsLoading", false);
                        reject(error);
                    });
            });
        },
        loadDepositId({ commit }, id) {
            return new Promise((resolve, reject) => {
                service
                    .getDetailDeposit(id)
                    .then((res) => {
                        commit("setDataBullionId", res);
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getWithdraw({ commit }, { currentPage, perPage, type, owner, startDate, endDate, status, serialNumber }) {
            commit("setIsLoading", true);
            return new Promise((resolve, reject) => {
                let offset = (currentPage - 1) * perPage;
                service
                    .getWithdrawalOn(offset, perPage, type, owner, startDate, endDate, status, serialNumber)
                    .then((res) => {
                        commit("setIsLoading", false);
                        let dataRes = res.data.data.body;
                        let count = res.data.data.count;
                        commit("setDataBullion", { dataRes, count });
                        resolve(res.data.data);
                    })
                    .catch((error) => {
                        commit("setIsLoading", false);
                        reject(error);
                    });
            });
        },
        getWithdrawOff({ commit }, { currentPage, perPage, denom, pieces, startDate, endDate, status, search }) {
            commit("setIsLoading", true);
            return new Promise((resolve, reject) => {
                let offset = (currentPage - 1) * perPage;
                service
                    .getWithdrawOffApi(offset, perPage, denom, pieces, startDate, endDate, status, search)
                    .then((res) => {
                        commit("setIsLoading", false);
                        let dataRes = res.data.data.data;
                        let count = res.data.data.total;
                        commit("setDataBullion", { dataRes, count });
                        resolve(res.data.data);
                    })
                    .catch((error) => {
                        commit("setIsLoading", false);
                        reject(error);
                    });
            });
        },
        getWithdrawOffDetail({ commit }, id) {
            commit("setIsLoading", true);
            return new Promise((resolve, reject) => {
                service
                    .getWithdrawOffDetailApi(id)
                    .then((res) => {
                        commit("setIsLoading", false);
                        commit("setDataWithdrawalDetail", res.data.data);
                        resolve(res.data.data);
                    })
                    .catch((error) => {
                        reject(error);
                        commit("setIsLoading", false);
                    });
            });
        },

        async getClientData({ commit }, typeExchange) {
            const response = await axios.get("/v2/clearing/admin/get/list_depositor?type=" + typeExchange);
            commit("setDataCLient", response.data.data.body);
        },

        async getContract({ commit }) {
            const response = await axios.get("/v2/attributes/contract");
            commit("setDataContract", response.data.data.body);
        },
    },
};

export default bullionManagement;
